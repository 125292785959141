import * as React from 'react'

import {
  AspectRatio,
  Box,
  Button,
  HStack,
  Image,
  Link,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'

import AddToCart from '../AddToCart'
import useAuth from '../useAuth'
import { useState } from 'react'

export const RelatedProductsCard = ({props}: RelatedProductsProps) => {
  const { loggedIn, user } = useAuth();
  const [isOnSale] = useState(props.onSale);

  return (
    <Stack spacing={useBreakpointValue({ base: '4', md: '5' })} height="100%" justifyContent="space-between" >
      <Link href={`/product/${props.slug}`} _hover={{textDecoration: "none !important"}}>
        <Box position="relative" maxW={{base: "100%", lg:"208px"}}>
          <AspectRatio ratio={4 / 3}>
            <Image
              src={props.image?.sourceUrl}
              draggable="false"
              fallback={<Skeleton />}
              borderRadius={useBreakpointValue({ base: 'md', md: 'xl' })}
              border="1px solid black" 
            />
          </AspectRatio>
        </Box>
        <Stack mt="24px">
          <Stack spacing="1">
            <Text fontWeight="medium" color={useColorModeValue('gray.700', 'gray.400')}>
              {props.name}
            </Text>
            <HStack>
                {isOnSale ? (
                  <><Text fontWeight="700" color="blue.600"> {props.price} </Text><Text fontSize="20px" style={{ textDecoration: 'line-through' }}>{props.regularPrice}</Text></>
                ): (
                  <Text fontWeight="700" color="blue.600">{props.regularPrice}</Text>
                )}
                </HStack>
             

          </Stack>
        </Stack>
      </Link>
      
      <Stack align="center">
          {!loggedIn ? (
            <Link className="chakra-button css-1oloaee" href='/log-in'  _hover={{textDecoration: "none !important"}} display='block' margin="0px auto !important" ><Button variant="solid" size="lg" mt="16px !important" colorScheme="white" bg="#0962e5"> Sign In To Order</Button></Link>
              ) : ( 
            <AddToCart productId={props.databaseId} />
           )}
           {/* <AddToCart productId={props.databaseId} /> */}
        </Stack>
    </Stack>  
  )
}

type RelatedProductsProps = {
  props: {
    name: String, 
    slug: String,
    price: string,
    regularPrice: string,
    onSale?: boolean,
    databaseId: number,
    image?: {
      sourceUrl?: string
    },
    productTags: {
      nodes?: []
      }
    }
}